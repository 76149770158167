import React, { Component } from 'react';
import { DateTime } from 'luxon';
import { Redirect } from 'react-router-dom';
import { Button, BackToButton, LineItem } from '@lexcelon/react-util';

import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { Accordion, AccordionSummary, AccordionDetails, Container, Grid, Typography } from '@mui/material';
import ImageViewer from 'react-simple-image-viewer';

import { setError } from '../../../alerts';

import { getTest } from '../../../api';

import { ExpandMore, ChevronLeft, ChevronRight } from '@mui/icons-material';

const INITIAL_STATE = {
  test: null,
  isImageViewerOpen: false,
  isOtherImageViewerOpen: false,
  selectedTreatments: [],
  notes: '',
  isLoading: false,
  sendResultsToOwner: true,
  inViewFoundParasites: [],
  inEditRecommendations: false,
  parentTest: null,
  navigationTests: []
};

class Test extends Component {
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
  }

  componentDidMount() {
    this.id = this.props.match?.params?.id;
    if (process.env.NODE_ENV !== 'development' || (process.env.NODE_ENV === 'development' && this.hasMountedAlready === true /* see note below */)) {
      getTest(this.id).then(test => {
        this.setState({ test });

        // Retrieve the relevant parent test
        let retrieveTestId = null;
        if (test.getParentTestId() != null) {
          if (test.getParentTest()?.getJointParentTestId() != null) retrieveTestId = test.getParentTest().getJointParentTestId();
          else retrieveTestId = test.getParentTestId();
        }
        else if (test.getJointParentTestId() != null) retrieveTestId = test.getJointParentTestId();

        if (retrieveTestId == null) return;

        getTest(retrieveTestId).then(parentTest => {
          this.setState({ parentTest });

          const testIds = [];
          if (parentTest.getJointChildTests() != null && parentTest.getJointChildTests().length > 0) {
            parentTest.getJointChildTests().forEach(jointTest => {
              if (jointTest.getChildTests()?.length > 0) {
                jointTest.getChildTests().forEach(childTest => testIds.push(childTest.getId()));
              }
              else testIds.push(jointTest.getId());
            });
          }
          else if (parentTest.getChildTests() != null && parentTest.getChildTests().length > 0) {
            parentTest.getChildTests().forEach(childTest => testIds.push(childTest.getId()));
          }

          const promises = testIds.map(testId => getTest(testId));
          Promise.all(promises).then(tests => {
            this.setState({ navigationTests: tests });
          }).catch(error => console.log(error));
        }).catch(error => console.log(error));
      }).catch(error => {
        setError(error ?? 'Error: Unable to retrieve test. Please try again.');
        this.setState({ redirect: true });
      });
    }

    /*
    * In dev mode, React.StrictMode renders everything twice, which is a huge problem when
    * we're talking about a connection to the instrument. To accommodate that without having
    * to get rid of React.StrictMode altogether (it is not granular), we check in development
    * mode to see if this is the second render before connecting to the websockets
    */
    this.hasMountedAlready = true;
  }

  componentDidUpdate(prevProps) {
    if (this.props.match?.params?.id !== prevProps.match?.params?.id) {
      this.setState(INITIAL_STATE, () => {
        this.componentDidMount();
      });
    }
  }

  renderJointTestButtons() {
    const { navigationTests, test } = this.state;

    if (navigationTests == null || navigationTests.length === 0) return null;

    const curTestIndex = navigationTests.findIndex(t => t.getId() === test.getId());
    const prevTest = curTestIndex > 0 ? navigationTests[curTestIndex - 1] : null;
    const nextTest = curTestIndex < navigationTests.length - 1 ? navigationTests[curTestIndex + 1] : null;

    return (
      <Grid container columnSpacing={2}>
        <Grid item xs={6}>
          {prevTest != null && <Button secondary style={{ width: '100%' }} component={Link} to={{ pathname: `/tests/${prevTest.getId()}`, state: this.props.location?.state }}>
            <ChevronLeft />
            {prevTest.getTestType()?.getName()}
          </Button>}
        </Grid>
        <Grid item xs={6}>
          {nextTest != null && <Button secondary style={{ width: '100%' }} component={Link} to={{ pathname: `/tests/${nextTest.getId()}`, state: this.props.location?.state }}>
            {nextTest.getTestType()?.getName()}
            <ChevronRight />
          </Button>}
        </Grid>
      </Grid>
    );
  }

  render() {
    if (this.state.test?.getChildTests()?.length > 0) {
      return <Redirect to={{ pathname: `/tests/${this.state.test?.getChildTests()[0].getId()}`, state: this.props.location?.state }} />;
    }
    else if (this.state.test?.getJointChildTests()?.length > 0) return <Redirect to={{ pathname: `/tests/${this.state.test?.getJointChildTests()[0].getId()}`, state: this.props.location?.state }} />;
    return (
      <Container style={{ paddingTop: '20px', paddingBottom: '30px' }}>
        {this.props.location?.state?.backTo != null &&
        <BackToButton to={this.props.location.state.backTo.pathname} description={this.props.location.state.backTo.description} />}

        {this.renderJointTestButtons()}

        <Typography variant='h1' style={{ textAlign: 'center', marginTop: '1em', marginBottom: '0.2em' }}>{this.state.test != null ? (this.state.test?.getAnimal()?.getName() + '\'s ') : ''}Fecal Egg Count Test Results</Typography>
        <Typography variant='subtitle1' style={{ textAlign: 'center', marginBottom: '2em', fontSize: '20px', fontWeight: 'bold', color: 'slategrey' }}>{this.state.test?.getTestType().getName()}</Typography>
        {this.state.test?.getStatus() !== 'COMPLETED' && <Typography variant='body1' style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '20px', color: 'red', fontStyle: 'italic' }}>{this.state.test?.getStatus()}</Typography>}

        <>
          {/* Large Image */}
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {this.state.test?.getMaskedCircledImagePath() != null ? (
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <img
                  src={this.state.test?.getMaskedCircledImagePath()}
                  onClick={() => this.setState({ isImageViewerOpen: true })}
                  width='90%'
                  style={{ margin: '2px' }}
                  alt=""
                  onError={(error) => console.log(error)}
                />
                <Typography variant='body1' style={{ color: 'slategrey', textAlign: 'center' }}>Click image for full screen and additional images.</Typography>
              </div>
            ) : (
              <Typography variant='body1' style={{ color: 'slategrey', fontStyle: 'italic' }}>No image available</Typography>
            )}
          </div>

          {this.state.isImageViewerOpen && (
            <ImageViewer
              src={[
                this.state.test?.getMaskedCircledImagePath(),
                this.state.test?.getCircledImagePath(),
                this.state.test?.getRawImagePath(),
              ]}
              currentIndex={0}
              disableScroll
              closeOnClickOutside={true}
              onClose={() => this.setState({ isImageViewerOpen: false })}
              backgroundStyle={{ backgroundColor: 'grey' }}
            />
          )}

          {this.state.isOtherImageViewerOpen && (
            <ImageViewer
              src={this.state.inViewFoundParasites.map(foundParasite => foundParasite.getImageUrl())}
              currentIndex={0}
              disableScroll
              closeOnClickOutside={true}
              onClose={() => this.setState({ isOtherImageViewerOpen: false, inViewFoundParasites: [] })}
              backgroundStyle={{ backgroundColor: 'grey' }}
            />
          )}

          <Typography variant='h2' style={{ marginTop: '2em' }}>Quantitative Results</Typography>
          <>
            <div style={{ marginTop: '1em' }}>
              {this.state.test?.getTestedParasites()?.map(testedParasite => (
                <Typography key={testedParasite.getId()} variant='body1' style={{ marginTop: '0.5em' }}>
                  <b>{testedParasite.getParasiteSpecies()?.getName()}</b>: 
                  {' ' + (this.state.test.getTestType().showEpg() ? testedParasite.getCount() + ' EPG' : (testedParasite.getCount() > 0 ? 'positive' : 'negative'))} {this.state.test.getTestType()?.getShowEggsCounted() ? ' (' + testedParasite.getCount() + ' eggs counted)' : ''}
                </Typography>
              ))}
            </div>

            <div style={{ marginTop: '1em' }}>
              {this.state.test?.getTestedParasites()?.map(testedParasite => testedParasite.getFoundParasites()?.length > 0 ? (
                <Accordion key={testedParasite.getId()} defaultExpanded elevation={10} style={{ marginTop: '0.5em' }}>
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography style={{ fontWeight: 'bold' }}>{testedParasite.getParasiteSpecies()?.getName()} Stained Images</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {testedParasite.getFoundParasites()?.map(foundParasite => (
                      <img
                        key={foundParasite.getId()}
                        src={foundParasite.getImageUrl()}
                        onClick={() => { } /*this.setState({ isOtherImageViewerOpen: true, inViewFoundParasites: testedParasite.getFoundParasites() })*/}
                        width="300"
                        style={{ margin: '2px' }}
                        alt=""
                      />
                    ))}
                  </AccordionDetails>
                </Accordion>
              ) : null)}
            </div>
          </>

          <Typography variant='h2' style={{ marginTop: '2em' }}>Test Overview</Typography>

          <LineItem
            description='Date/Time'
            value={this.state.test?.getTimestamp()?.toLocaleString({ ...DateTime.DATETIME_MED, timeZoneName: 'short' })}
          />

          <LineItem
            description='Test ID'
            value={this.state.test?.getId()}
          />

          <LineItem
            description='Patient'
            value={`${this.state.test?.getAnimal().getName()} (${this.state.test?.getAnimal().getAnimalSpecies().getName()})`}
          />

          <LineItem
            description='Patient Age at Time of Test'
            value={this.state.test?.getAnimalAge()}
          />

          <LineItem
            description='Patient Owner'
            value={`${this.state.test?.getAnimal().getOwnerFirstName()} ${this.state.test?.getAnimal().getOwnerLastName()}${this.state.test?.getAnimal().getOwnerEmail() != null ? ` (${this.state.test?.getAnimal().getOwnerEmail()})` : ''}`}
          />

          <LineItem
            description='Veterinarian'
            value={`${this.state.test?.getVeterinarian()?.getFullName()} (${this.state.test?.getVeterinarian()?.getEmail()})`}
          />

          {this.state.test?.getLabTech() != null &&
            <LineItem
              description='Lab Tech'
              value={`${this.state.test?.getLabTech()?.getFullName()} (${this.state.test?.getLabTech()?.getEmail()})`}
            />}

          {this.state.test?.getSampleName() != null &&
            <LineItem
              description='Sample Name'
              value={this.state.test.getSampleName()}
            />}

          {this.state.test?.getComments() != null &&
            <LineItem
              description='Comments'
              value={this.state.test.getComments()}
            />}

          <LineItem
            description='Reduction Test'
            value={this.state.test?.getIsReductionTest() ? 'Yes' : 'No'}
          />


          <Typography variant='h2' style={{ marginTop: '2em', marginBottom: '0.5em' }}> Recommendations and Notes </Typography>

          {this.state.test?.getWasModified() &&
          <Typography variant='body1' style={{ color: 'red', fontStyle: 'italic' }}>The test recommendations have been modified.</Typography>}

          <LineItem
            value={this.state.test?.getTreatments()?.map(treatment => treatment.getName())?.join(', ')}
            description={'Recommended Treatments'}
          />

          <LineItem
            value={this.state.test?.getNotes()}
            description={'Notes'}
          />

          <Typography variant='h2' style={{ marginTop: '2em', marginBottom: '0.5em' }}> Debug Information </Typography>

          <LineItem
            description='Instrument Serial Number'
            value={this.state.test?.getInstrumentSerialNumber()}
          />

          <LineItem
            description='System Info'
            value={this.state.test?.getSystemInfo()}
          />

          <LineItem
            description='Client IP'
            value={this.state.test?.getClientIp() ?? 'Unknown'}
          />

          <LineItem
            description='Recipe ID'
            value={this.state.test?.getRecipe()?.id}
          />

          <LineItem
            description='Image Detection URL'
            value={this.state.test?.getImageDetectionUrl()}
          />

          <LineItem
            description='Confidence Thresholds'
            value={this.state.test?.getConfidenceThresholds()}
          />

          <LineItem
            description='Correction Factor'
            value={this.state.test?.getCorrectionFactor()}
          />

          <LineItem
            description='Audited By Admin'
            value={this.state.test?.getAuditedByAdminUser() ? 
              'PASS' :
              this.state.test?.getAuditedByAdminUser() === null ? 'TODO' :
                'FAILED'
            }
          />

          <Button secondary="true" component={Link} to={{ pathname: `/tests/${this.id}/audit`, state: { backTo: { pathname: { pathname: `/tests/${this.id}`, state: this.props.location?.state }, description: 'Test Results' } } }}>
            Audit Test
          </Button>

          <div style={{ marginTop: '20px' }}>
            {this.renderJointTestButtons()}
          </div>

        </>
      </Container>
    );
  }
}

Test.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default withRouter(Test);
